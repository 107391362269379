import Plan, { Price } from '../interfaces/plan';

export const planTiers = ['premium-rewards', 'premium', 'basic']; // removed 'plus'

export const getCurrencyForCountry = (countryCode: string | null | undefined) => {
  const currencies: any = {
    US: 'USD',
    CA: 'CAD',
    GB: 'GBP',
  };
  return (countryCode && currencies[countryCode]) || 'USD';
};

export const getCurrencySymbol = (currency: string) => {
  const currencies: any = {
    USD: '$',
    CAD: '$',
    GBP: '£',
  };
  return currencies[currency];
};

export const getFamilyForCountry = (countryCode: string | null | undefined) => {
  const currencies: any = {
    US: 'Membership',
    CA: 'Membership',
    GB: 'International',
  };
  return (countryCode && currencies[countryCode]) || 'Membership';
};

export const getTier = (priceId: string) =>
  planTiers.find((tier) => priceId.toLowerCase().includes(tier));

export const subscriptionStatus = {
  // see https://apidocs.chargebee.com/docs/api/subscriptions?prod_cat_ver=2#subscription_attributes
  future: 'future',
  trial: 'in_trial',
  active: 'active',
  nonrenewing: 'non_renewing',
  paused: 'paused',
  cancelled: 'cancelled',
};

const basicPriceMeta = {
  id: 'Basic',
  plan: 'Basic',
  family: '',
  amount: 0,
  description:
    'For the casual sneaker buyer. Gain access to our one-of-a kind marketplace built for you, not resellers.',
  benefits: [
    'DROPS app: calendar of every confirmed release',
    'COLLECT app: buy sell trade with the lowest fees in the industry (3%, $8 flat to trade) (US/CA only)',
    'COLLECT app: digital closet to manage your collection (US/CA only)',
  ],
};

const basicPlan: Plan = {
  id: 'Basic',
  name: 'Basic',
  family: '',
  prices: [
    {
      period: 'year',
      ...basicPriceMeta,
    },
    {
      period: 'month',
      ...basicPriceMeta,
    },
  ] as Price[],
};

export default basicPlan;
